import React from "react"
import { Link } from "gatsby"


const Modeling = () => {
        return (
                <>
                        <section>
                                <div className="parallax">
                                        <img src="/img/black-illustration-on-white-printer-paper-716661.jpg" alt="" />
                                        <div className="overlay"></div>
                                        <div className="headercontent">
                                                <h1>3D-MODELING</h1>
                                        </div>
                                </div>
                        </section>


                        <section className="cad">
                                <div className="container pt-4">
                                        <div className="row">
                                                <div className="col-12 col-mg-12">
                                                        <h4 className="modeling">3D Modeling Services:</h4>
                                                        <p>3D modeling services are being done with certified team members, they are well experienced in the industry to create a design in quick turnaround time. Innnoway Systems can provide you with excellent 3D modeling services for any project that you have. The training, skills acquired, creativity, experience and technology are able to speed up your product development process through 3D modeling. </p>
                                                        <h4 className="modeling">3D Modeling Services Offered:</h4>
                                                        <p>We are able to provide you with 3D modeling services for your imagination or engineering analysis, it aids your development process more efficient. Our team will create 3D model of objects for your project through the use of efficient software. The 3D model can be used for media, print or Internet promotion. We’ll give you an accurate and marketable 3D Computer-aided Design (CAD) modeling that will satisfy your company needs.</p>
                                                        <p>We are capable to create 3D models for mechanical machine components, architectural presentations; we can create highly realistic and detailed views of your structures from multiple angles of 3D solid modeling. </p>
                                                        <p>The following are some of the basic services we provide:</p>
                                                        <ul>
                                                                <li>Automotive parts</li>
                                                                <li>Aerospace Components</li>
                                                                <li>Precision Components</li>
                                                                <li>Process equipment</li>
                                                                <li>Sheet Metal</li>
                                                                <li>Freeform surface modeling</li>
                                                                <li>Assembly modeling and interface check</li>
                                                                <li>3D model of machine parts</li>

                                                        </ul>
                                                        <h4 className="modeling">Architectural</h4>
                                                        <ul>
                                                                <li>Residential Buildings</li>
                                                                <li>Architectural models</li>

                                                        </ul>
                                                        <p>Every delivery file undergoes gate check points to ensure the required format and quality output. </p>
                                                        <p>You are welcome to discuss with our experts about your requirement to get more information about the project for better decision making. At Innoway systems we do understand the pressing needs of a customer and do not sell our services, but we guide our clients to decide themselves about selecting the service. Ours is a customized service, if you need 3D modeling services for your firm, contact us today at Innoway Systems for a suitable 3D modeling options.</p><br />
                                                        <div className="modelings"> <Link to="/contactus/" className="btn btn-danger">Contact Now</Link><br /> <br /></div>
                                                </div>

                                        </div>
                                </div>
                        </section>


                </>
        )
}

export default Modeling
