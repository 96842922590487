import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ModelingPage from "../../components/services/3dmodeling/index"
const Modeling = () => {
  return (
    <Layout>
      <SEO
        title="3D Modeling Services in India - Innoway Systems"
        desc="3D modeling services are being done with certified team members, they are well experienced in the industry to create a design in quick turnaround time."
        pathname="/services/3d-modeling/"
        keywords="Mechanical and Architectural in coimbatore "
      />
      <ModelingPage />
    </Layout>
  )
}

export default Modeling
